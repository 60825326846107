import axios from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const API_BASE_URL = process.env.REACT_APP_API_HOST;

const api = axios.create({
    baseURL: API_BASE_URL + '/api/v1'
});

export const fetchSummary = (params) => api.get('/summary', { params });
export const fetchFiles = (params) => api.get('/storage/files', { params });
export const uploadFiles = (formData, { year, month, warehouse }) => api.post(`/storage/upload?year=${year}&month=${month}&warehouse=${warehouse}`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
export const deleteFiles = (year, month, fileName) => api.delete(`/storage/delete?year=${year}&month=${month}`, {
    data: [fileName]
});
export const fetchWarehouses = () => api.get('/storage/warehouses');

export default api;