// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Link, Navigate, Route, Routes } from 'react-router-dom';
import FileManager from './components/FileManager';
import Summary from './components/Summary';
import Footer from './components/Footer';
import WarehouseConfig from './components/WarehouseConfig';
import { AppBar, Button, Container, Toolbar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSelector from './components/LanguageSelector';
import Comparison from './components/Comparison';
import { useTranslation } from 'react-i18next';

const App = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Router>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen} sx={{ display: { xs: 'block', md: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                        <Button color="inherit" component={Link} to="/summary">{t('menu.summary')}</Button>
                        <Button color="inherit" component={Link} to="/comparison">{t('menu.comparison')}</Button>
                        <Button color="inherit" component={Link} to="/warehouse-config">{t('menu.warehouseConfig')}</Button>
                        <Button color="inherit" component={Link} to="/file-manager">{t('menu.fileManager')}</Button>
                    </Typography>
                    <LanguageSelector />
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <MenuItem onClick={handleMenuClose} component={Link} to="/summary">{t('menu.summary')}</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/comparison">{t('menu.comparison')}</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/warehouse-config">{t('menu.warehouseConfig')}</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/file-manager">{t('menu.fileManager')}</MenuItem>
            </Menu>
            <Container>
                <Routes>
                    <Route path="/summary" element={<Summary />} />
                    <Route path="/comparison" element={<Comparison />} />
                    <Route path="/warehouse-config" element={<WarehouseConfig />} />
                    <Route path="/file-manager" element={<FileManager />} />
                    <Route path="*" element={<Navigate to="/summary" />} />
                </Routes>
            </Container>
            <Footer />
        </Router>
    );
};

export default App;