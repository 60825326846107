import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "menu": {
                "summary": "Summary",
                "fileManager": "File Manager",
                "comparison": "Comparison",
                "warehouseConfig": "Warehouse"
            },
            "salesSummary": "Sales Summary",
            "year": "Year",
            "month": "Month",
            "months": "Months",
            "city": "City",
            "view": "View",
            "warehouse": "Warehouse",
            "pharmacy": "Pharmacy",
            "zipcode": "Zipcode",
            "total": "Total",
            "fileManager": "Spreadsheets",
            "selectFiles": "Select Files",
            "selectedFiles": "Selected Files",
            "upload": "Upload",
            "files": "Files",
            "warehouseSales": "Warehouse Sales",
            "pharmacySales": "Pharmacy Sales",
            "productSales": "Product Sales",
            "suggestionsTitle": "Suggestions",
            "noSuggestions": "No suggestions available.",
            "comparison": "Comparison",
            "fromUntil": "From {{from}} Until {{until}}",
            "January": "January",
            "February": "February",
            "March": "March",
            "April": "April",
            "May": "May",
            "June": "June",
            "July": "July",
            "August": "August",
            "September": "September",
            "October": "October",
            "November": "November",
            "December": "December",
            "warehouseConfigs": "Warehouse Configurations",
            "addNewConfig": "Add New Configuration",
            "name": "Name",
            "qtdSheets": "Quantity of Sheets",
            "productNameColumn": "Product Name Column",
            "pharmacyNameColumn": "Pharmacy Name Column",
            "quantitySoldColumn": "Quantity Sold Column",
            "cityColumn": "City Column",
            "zipCodeColumn": "Zip Code Column",
            "warehouseConfig": "Warehouse Configurations",
            "enterSingleLetter": "Enter a single letter",
            "add": "Add",
            "addSuccess": "Configuration added successfully",
            "addError": "Error adding configuration",
            "selectPeriod": "Select Period",
            "salesByProduct": "Sales by Product",
            "fromMonth": "From Month",
            "toMonth": "To Month",
            "currentConfigs": "Current Configurations",
            "totalSold": "Total Sold",
            "confirmation": "Upload File",
            "confirmUpload": "Want to upload files to warehouse {{warehouse}} for the period {{year}}/{{month}}?",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "salesByWarehouse": "Sales by Warehouse",
            "topCities": "Top Cities",
            "analyze": "Analyse",
            "selectAllWarehouses": "Select All Warehouses",
        }
    },
    pt: {
        translation: {
            "menu": {
                "summary": "Resumo",
                "fileManager": "Planilhas",
                "comparison": "Comparação",
                "warehouseConfig": "Armazenistas"
            },
            "salesSummary": "Resumo de Vendas",
            "year": "Ano",
            "month": "Mês",
            "months": "Meses",
            "city": "Cidade",
            "view": "Vista",
            "warehouse": "Armazém",
            "pharmacy": "Farmácia",
            "zipcode": "Código Postal",
            "total": "Total",
            "fileManager": "Gerenciador de Arquivos",
            "selectFiles": "Selecionar Arquivos",
            "selectedFiles": "Arquivos Selecionados",
            "upload": "Upload",
            "files": "Arquivos",
            "warehouseSales": "Vendas por Armazém",
            "pharmacySales": "Vendas por Farmácia",
            "productSales": "Vendas por Produto",
            "suggestionsTitle": "Sugestões",
            "noSuggestions": "Nenhuma sugestão disponível.",
            "comparison": "Comparação",
            "fromUntil": "De {{from}} Até {{until}}",
            "January": "Janeiro",
            "February": "Fevereiro",
            "March": "Março",
            "April": "Abril",
            "May": "Maio",
            "June": "Junho",
            "July": "Julho",
            "August": "Agosto",
            "September": "Setembro",
            "October": "Outubro",
            "November": "Novembro",
            "December": "Dezembro",
            "warehouseConfigs": "Configurações de Armazéns",
            "addNewConfig": "Adicionar Nova Configuração",
            "name": "Nome",
            "qtdSheets": "Quantidade de Planilhas",
            "productNameColumn": "Coluna do Nome do Produto",
            "pharmacyNameColumn": "Coluna do Nome da Farmácia",
            "quantitySoldColumn": "Coluna de Quantidade Vendida",
            "cityColumn": "Coluna da Cidade",
            "zipCodeColumn": "Coluna do Código Postal",
            "warehouseConfig": "Configurações de Armazéns",
            "enterSingleLetter": "Digite uma única letra",
            "add": "Adicionar",
            "addSuccess": "Configuração adicionada com sucesso",
            "addError": "Erro ao adicionar configuração",
            "selectPeriod": "Selecione o Período",
            "salesByProduct": "Vendas por Produto",
            "fromMonth": "De Mês",
            "toMonth": "Até Mês",
            "currentConfigs": "Configurações Atuais",
            "totalSold": "Total Vendido",
            "confirmation": "Upload de Arquivo",
            "confirmUpload": "Deseja fazer upload dos arquivos para o armazém {{warehouse}} do período {{year}}/{{month}}?",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "salesByWarehouse": "Vendas por Armazém",
            "topCities": "Principais Cidades",
            "analyze": "Analisar",
            "selectAllWarehouses": "Selecionar Todos os Armazéns",
        }
    },
    es: {
        translation: {
            "menu": {
                "summary": "Resumen",
                "fileManager": "Archivos",
                "comparison": "Comparación",
                "warehouseConfig": "Almacenes"
            },
            "salesSummary": "Resumen de Ventas",
            "year": "Año",
            "month": "Mes",
            "months": "Meses",
            "city": "Ciudad",
            "view": "Vista",
            "warehouse": "Almacén",
            "pharmacy": "Farmacia",
            "zipcode": "Código Postal",
            "total": "Total",
            "fileManager": "Gestor de Archivos",
            "selectFiles": "Seleccionar Archivos",
            "selectedFiles": "Archivos Seleccionados",
            "upload": "Subir",
            "files": "Archivos",
            "warehouseSales": "Ventas por Almacén",
            "pharmacySales": "Ventas por Farmacia",
            "productSales": "Ventas por Producto",
            "suggestionsTitle": "Sugerencias",
            "noSuggestions": "No hay sugerencias disponibles.",
            "comparison": "Comparison",
            "fromUntil": "De {{from}} Hasta {{until}}",
            "January": "Enero",
            "February": "Febrero",
            "March": "Marzo",
            "April": "Abril",
            "May": "Mayo",
            "June": "Junio",
            "July": "Julio",
            "August": "Agosto",
            "September": "Septiembre",
            "October": "Octubre",
            "November": "Noviembre",
            "December": "Diciembre",
            "warehouseConfigs": "Configuraciones de Almacenes",
            "addNewConfig": "Agregar Nueva Configuración",
            "name": "Nombre",
            "qtdSheets": "Cantidad de Hojas",
            "productNameColumn": "Columna del Nombre del Producto",
            "pharmacyNameColumn": "Columna del Nombre de la Farmacia",
            "quantitySoldColumn": "Columna de Cantidad Vendida",
            "cityColumn": "Columna de la Ciudad",
            "zipCodeColumn": "Columna del Código Postal",
            "warehouseConfig": "Configuraciones de Almacenes",
            "enterSingleLetter": "Ingrese una sola letra",
            "add": "Agregar",
            "addSuccess": "Configuración agregada con éxito",
            "addError": "Error al agregar configuración",
            "selectPeriod": "Seleccione el Período",
            "salesByProduct": "Ventas por Producto",
            "fromMonth": "Desde Mes",
            "toMonth": "Hasta Mes",
            "currentConfigs": "Configuraciones Actuales",
            "totalSold": "Total Vendido",
            "confirmation": "Subir Archivo",
            "confirmUpload": "¿Desea subir los archivos al almacén {{warehouse}} para el período {{year}}/{{month}}?",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "salesByWarehouse": "Ventas por Almacén",
            "topCities": "Principales Ciudades",
            "analyze": "Analizar",
            "selectAllWarehouses": "Seleccionar Todos los Almacenes",
        }
    }
};

const storedLanguage = localStorage.getItem('i18nextLng') || 'pt';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: storedLanguage,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;