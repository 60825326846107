import React, { useState, useEffect } from 'react';
import axios from '../services/api';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';

const WarehouseConfig = () => {
    const { t } = useTranslation();
    const [configs, setConfigs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newConfig, setNewConfig] = useState({
        name: '',
        qtdSheets: 1,
        productNameColumn: '',
        pharmacyNameColumn: '',
        quantitySoldColumn: '',
        cityColumn: '',
        zipCodeColumn: ''
    });
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        fetchConfigs();
    }, []);

    const fetchConfigs = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/storage/warehouses-config');
            setConfigs(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'qtdSheets') {
            const numericValue = parseInt(value, 10);
            if (numericValue < 1) return;
        } else if (name !== 'name' && value !== '' && !/^[a-zA-Z]$/.test(value)) {
            return; // Ignore invalid input
        }
        setNewConfig({
            ...newConfig,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post('/storage/warehouses-config', newConfig);
            setAlert({ open: true, message: t('addSuccess'), severity: 'success' });
            fetchConfigs();
            setNewConfig({
                name: '',
                qtdSheets: 1,
                productNameColumn: '',
                pharmacyNameColumn: '',
                quantitySoldColumn: '',
                cityColumn: '',
                zipCodeColumn: ''
            });
        } catch (error) {
            console.error(error);
            setAlert({ open: true, message: t('addError'), severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ margin: 4 }}>
            <Typography variant="h4" gutterBottom>
                {t('warehouseConfig')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ marginBottom: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t('name')}
                            name="name"
                            value={newConfig.name}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t('qtdSheets')}
                            name="qtdSheets"
                            type="number"
                            inputProps={{ min: 1 }}
                            value={newConfig.qtdSheets}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={`${t('productNameColumn')} (A-Z)`}
                            name="productNameColumn"
                            inputProps={{ maxLength: 1, pattern: "[a-zA-Z]" }}
                            helperText={t('enterSingleLetter')}
                            value={newConfig.productNameColumn}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={`${t('pharmacyNameColumn')} (A-Z)`}
                            name="pharmacyNameColumn"
                            inputProps={{ maxLength: 1, pattern: "[a-zA-Z]" }}
                            helperText={t('enterSingleLetter')}
                            value={newConfig.pharmacyNameColumn}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={`${t('quantitySoldColumn')} (A-Z)`}
                            name="quantitySoldColumn"
                            inputProps={{ maxLength: 1, pattern: "[a-zA-Z]" }}
                            helperText={t('enterSingleLetter')}
                            value={newConfig.quantitySoldColumn}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={`${t('cityColumn')} (A-Z)`}
                            name="cityColumn"
                            inputProps={{ maxLength: 1, pattern: "[a-zA-Z]" }}
                            helperText={t('enterSingleLetter')}
                            value={newConfig.cityColumn}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={`${t('zipCodeColumn')} (A-Z)`}
                            name="zipCodeColumn"
                            inputProps={{ maxLength: 1, pattern: "[a-zA-Z]" }}
                            helperText={t('enterSingleLetter')}
                            value={newConfig.zipCodeColumn}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ backgroundColor: '#4caf50', color: '#fff', mt: 2 }}
                            fullWidth
                        >
                            {t('add')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Typography variant="h5" gutterBottom>
                {t('currentConfigs')}
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('name')}</TableCell>
                                <TableCell>{t('qtdSheets')}</TableCell>
                                <TableCell>{t('productNameColumn')}</TableCell>
                                <TableCell>{t('pharmacyNameColumn')}</TableCell>
                                <TableCell>{t('quantitySoldColumn')}</TableCell>
                                <TableCell>{t('cityColumn')}</TableCell>
                                <TableCell>{t('zipCodeColumn')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {configs.map((config) => (
                                <TableRow key={config.name}>
                                    <TableCell>{config.name}</TableCell>
                                    <TableCell>{config.qtdSheets}</TableCell>
                                    <TableCell>{config.productNameColumn}</TableCell>
                                    <TableCell>{config.pharmacyNameColumn}</TableCell>
                                    <TableCell>{config.quantitySoldColumn}</TableCell>
                                    <TableCell>{config.cityColumn}</TableCell>
                                    <TableCell>{config.zipCodeColumn}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => setAlert({ ...alert, open: false })}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default WarehouseConfig;