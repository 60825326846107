import React, { useState, useEffect } from 'react';
import axios from '../services/api';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
    TextField,
    useMediaQuery,
    useTheme,
    Checkbox,
    ListItemText,
    FormControlLabel,
    Button
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ResponsiveContainer } from 'recharts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Container = styled(Box)(({ theme }) => ({
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Comparison = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [fromMonth, setFromMonth] = useState('');
    const [toMonth, setToMonth] = useState('');
    const [city, setCity] = useState(localStorage.getItem('city') || 'Lisbon');
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [allWarehousesSelected, setAllWarehousesSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState([]);
    const [salesByProductData, setSalesByProductData] = useState([]);
    const [salesByWarehouseData, setSalesByWarehouseData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const response = await axios.get('/storage/warehouses');
                setWarehouses(response.data);
            } catch (error) {
                console.error('Failed to fetch warehouses', error);
            }
        };
        fetchWarehouses();
    }, []);

    const fetchComparisonData = async () => {
        setLoading(true);
        setDataLoaded(false); // Reset dataLoaded before fetching data

        const productData = [];
        const salesByProductData = {};
        const salesByWarehouseData = {};

        const fromDate = moment(fromMonth, 'YYYY-MM');
        const toDate = moment(toMonth, 'YYYY-MM');
        const months = toDate.diff(fromDate, 'months') + 1;

        const warehousesParam = selectedWarehouses.join(','); // Join selected warehouses with commas

        for (let i = 0; i < months; i++) {
            const date = moment(fromMonth, 'YYYY-MM').add(i, 'months');
            const year = date.year();
            const month = date.format('MM');

            try {
                const response = await axios.get('/summary', {
                    params: {
                        year,
                        month,
                        city,
                        view: 'product',
                        warehouses: warehousesParam // Pass the comma-separated warehouse names
                    }
                });
                const monthData = {
                    month: `${t(date.format('MMMM'))} ${year}`,
                    totalSold: response.data.reduce((sum, item) => sum + Object.values(item.productQuantities).reduce((total, qty) => total + qty, 0), 0)
                };
                productData.push(monthData);

                response.data.forEach(item => {
                    Object.keys(item.productQuantities).forEach(productName => {
                        if (!salesByProductData[productName]) {
                            salesByProductData[productName] = { name: productName };
                        }
                        if (!salesByProductData[productName][`${t(date.format('MMMM'))} ${year}`]) {
                            salesByProductData[productName][`${t(date.format('MMMM'))} ${year}`] = 0;
                        }
                        salesByProductData[productName][`${t(date.format('MMMM'))} ${year}`] += item.productQuantities[productName];
                    });

                    if (!salesByWarehouseData[item.warehouseName]) {
                        salesByWarehouseData[item.warehouseName] = { name: item.warehouseName };
                    }
                    if (!salesByWarehouseData[item.warehouseName][`${t(date.format('MMMM'))} ${year}`]) {
                        salesByWarehouseData[item.warehouseName][`${t(date.format('MMMM'))} ${year}`] = 0;
                    }
                    salesByWarehouseData[item.warehouseName][`${t(date.format('MMMM'))} ${year}`] += Object.values(item.productQuantities).reduce((total, qty) => total + qty, 0);
                });
            } catch (error) {
                console.error(error);
            }
        }

        productData.sort((a, b) => moment(a.month, 'MMMM YYYY').unix() - moment(b.month, 'MMMM YYYY').unix());
        setProductData(productData);

        const productNames = Object.keys(salesByProductData);
        const warehouseNames = Object.keys(salesByWarehouseData);
        const monthsList = Array.from(new Set(productData.map(data => data.month)));

        const aggregatedSalesByProductData = monthsList.map(month => {
            const dataEntry = { month };
            productNames.forEach(productName => {
                dataEntry[productName] = salesByProductData[productName][month] || 0;
            });
            return dataEntry;
        });

        const aggregatedSalesByWarehouseData = monthsList.map(month => {
            const dataEntry = { month };
            warehouseNames.forEach(warehouseName => {
                dataEntry[warehouseName] = salesByWarehouseData[warehouseName][month] || 0;
            });
            return dataEntry;
        });

        setSalesByProductData(aggregatedSalesByProductData);
        setSalesByWarehouseData(aggregatedSalesByWarehouseData);
        setLoading(false);
        setDataLoaded(true); // Indicate that data has been successfully loaded
    };

    const handleFromMonthChange = (e) => {
        setFromMonth(e.target.value);
    };

    const handleToMonthChange = (e) => {
        setToMonth(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleWarehouseChange = (e) => {
        const { value, checked } = e.target;
        setSelectedWarehouses((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((w) => w !== value)
        );
    };

    const handleSelectAllWarehouses = (e) => {
        const { checked } = e.target;
        setAllWarehousesSelected(checked);
        setSelectedWarehouses(checked ? warehouses : []);
    };

    const getMonthOptions = () => {
        const options = [];
        for (let i = 0; i < 12; i++) {
            const date = moment().subtract(i + 1, 'months');
            options.push(
              <MenuItem key={date.format('YYYY-MM')} value={date.format('YYYY-MM')}>
                  {`${t(date.format('MMMM'))} ${date.year()}`}
              </MenuItem>
            );
        }
        return options;
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: t('comparison'),
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    weight: 'bold'
                },
                formatter: (value) => value
            }
        },
    };

    const colors = [
        '#8884d8', '#82ca9d', '#ffc658', '#d0ed57', '#a4de6c',
        '#d88484', '#84d8d8', '#d88d84', '#84d88d', '#d8d8d8'
    ];

    const getColor = (index) => {
        return colors[index] || getRandomColor();
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
      <Container>
          <Typography variant="h4" gutterBottom>
              {t('comparison')}
          </Typography>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('fromMonth')}</InputLabel>
                      <Select
                        value={fromMonth}
                        onChange={handleFromMonthChange}
                        label={t('fromMonth')}
                        disabled={loading}
                      >
                          {getMonthOptions()}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('toMonth')}</InputLabel>
                      <Select
                        value={toMonth}
                        onChange={handleToMonthChange}
                        label={t('toMonth')}
                        disabled={loading}
                      >
                          {getMonthOptions()}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('city')}
                    value={city}
                    onChange={handleCityChange}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {['Lisbon', 'Porto'].map((cityOption) => (
                        <MenuItem key={cityOption} value={cityOption}>
                            {cityOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                      <InputLabel>{t('warehouse')}</InputLabel>
                      <Select
                        multiple
                        value={selectedWarehouses}
                        onChange={handleWarehouseChange}
                        renderValue={(selected) => selected.join(', ')}
                        disabled={loading}
                      >
                          {warehouses.map((warehouse) => (
                            <MenuItem key={warehouse} value={warehouse}>
                                <Checkbox
                                  checked={selectedWarehouses.includes(warehouse)}
                                  value={warehouse}
                                  onChange={handleWarehouseChange}
                                />
                                <ListItemText primary={warehouse} />
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    control={
                        <Checkbox
                          checked={allWarehousesSelected}
                          onChange={handleSelectAllWarehouses}
                        />
                    }
                    label={t('selectAllWarehouses')}
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchComparisonData}
                    disabled={loading || !fromMonth || !toMonth || selectedWarehouses.length === 0}
                    fullWidth
                  >
                      {t('analyze')}
                  </Button>
              </Grid>
          </Grid>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                <CircularProgress />
            </Box>
          ) : (
            dataLoaded && (
              <>
                  <Box mt={4}>
                      <Typography variant="h5" gutterBottom>
                          {t('productSales')}
                      </Typography>
                      <ResponsiveContainer width="100%" height={400}>
                          <Bar
                            options={chartOptions}
                            data={{
                                labels: productData.map(data => data.month),
                                datasets: [
                                    {
                                        label: t('totalSold'),
                                        data: productData.map(data => data.totalSold),
                                        backgroundColor: '#8884d8',
                                    },
                                ],
                            }}
                          />
                      </ResponsiveContainer>
                  </Box>
                  <Box mt={4}>
                      <Typography variant="h5" gutterBottom>
                          {t('salesByProduct')}
                      </Typography>
                      <ResponsiveContainer width="100%" height={400}>
                          <Bar
                            options={chartOptions}
                            data={{
                                labels: salesByProductData.map(data => data.month),
                                datasets: Object.keys(salesByProductData[0] || {}).filter(key => key !== 'month').map((key, index) => ({
                                    label: key,
                                    data: salesByProductData.map(data => data[key]),
                                    backgroundColor: getColor(index),
                                })),
                            }}
                          />
                      </ResponsiveContainer>
                  </Box>
                  <Box mt={4}>
                      <Typography variant="h5" gutterBottom>
                          {t('salesByWarehouse')}
                      </Typography>
                      <ResponsiveContainer width="100%" height={400}>
                          <Bar
                            options={chartOptions}
                            data={{
                                labels: salesByWarehouseData.map(data => data.month),
                                datasets: Object.keys(salesByWarehouseData[0] || {}).filter(key => key !== 'month').map((key, index) => ({
                                    label: key,
                                    data: salesByWarehouseData.map(data => data[key]),
                                    backgroundColor: getColor(index),
                                })),
                            }}
                          />
                      </ResponsiveContainer>
                  </Box>
              </>
            )
          )}
      </Container>
    );
};

export default Comparison;